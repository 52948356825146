import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BsCart } from 'react-icons/bs';
import { FaRegUser } from 'react-icons/fa6';
import { Container } from 'react-bootstrap';
import SearchBar from './SearchBar'; // Import the SearchBar component
import './Header.css';
import axios from "axios";
import logo from "./Images/favicon.jpeg"
const Header = () => {
    const [username, setUsername] = useState(localStorage.getItem('username') || "");
    const [cartProductsCount, setCartProductsCount] = useState(0); // Initialize cart products count to 0
    localStorage.setItem("cartcount", cartProductsCount.cartcount);
    useEffect(() => {
        const fetchCartProductsCount = async () => {
            const cartId = localStorage.getItem('cart_id');
            try {
                const cartResponse = await axios.get(
                    `https://electronicsapi.codingster.in/Cartcount?cart_id=${cartId}`
                );
                debugger;
                if (cartResponse.status == 200) {
                    setCartProductsCount(cartResponse.data);

                }
            } catch (error) {
                console.error("Error fetching cart details:", error);
            }
            // try {
            //     const cartId = localStorage.getItem('cart_id');
            //     if(cartId){
            //         const response = await fetch(`https://localhost:44350/Cartcount?cart_id=${cartId}`);
            //         debugger;
            //         if (response.data) {
            //             const data = await response.json();
            //             setCartProductsCount(data); // Set the count of cart products
            //         } else {
            //             console.error('Failed to fetch cart products count:', response.statusText);
            //         }
            //     }


            // } catch (error) {
            //     console.error('Error fetching cart products count:', error);
            // }
        };

        fetchCartProductsCount();
    }, []);

    return (
        <Container>
            <header className="header">
                <Link to="/" className="header__logo">
                   <img src={logo} className='logo' alt="" /> CAPTAIN CART
                </Link>
                <SearchBar />

                <div className="header__icons">
                    <div className="header__icon">
                        <Link className='link' to="/cart">
                            <BsCart className="cart-icon" />
                            <span className="cart-count">{cartProductsCount.cartcount}</span>
                        </Link>

                    </div>
                    {username ? (
                        <div className="header__icon">
                            <Link to="/profile" className="link">
                                <FaRegUser />
                                <span className="username">{username}</span>
                            </Link>
                        </div>
                    ) : (
                        <div className="header__icon">
                            <Link className='link' to="/login">
                                <FaRegUser />
                                <span className="signin-text"></span>
                            </Link>
                        </div>
                    )}
                </div>
            </header>
        </Container>
    );
};

export default Header;