import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./Login.css";

function Login() {
debugger;
  const [formData, setFormData] = useState({
    phone: "",
    password: "",
    usertype: "4",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    const url = "https://electronicsapi.codingster.in/Login";
    const data = new URLSearchParams(formData);
debugger;
    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      const responseData = await response.json();

      if (responseData.responseCode === 0) {
        localStorage.setItem("author_id", responseData.responseid);
        localStorage.setItem("usertype", responseData.responsetype);
        localStorage.setItem("username", responseData.responseobject);

        // Login successful
        toast.success(responseData.responsemessage, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        // Redirect to home page
        window.location.href = "/home";
      } else {
        // Login failed
        toast.error(
          responseData.responsemessage || "Invalid credentials.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } catch (error) {
      // Error during login
      toast.error("An error occurred during login.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.error("Fetch error:", error);
    }
  };

  return (
    <Container fluid className="custom-container">
      <Row className="login" noGutters>
        <Col md={6} className="image-column mt-5">
          <img
            src="https://img.freepik.com/free-vector/computer-login-concept-illustration_114360-7862.jpg"
            alt="Login Image"
            className="img-fluid"
          />
        </Col>
        <Col md={6} className="form-column mt-5">
          <div className="form-wrapper">
            <Form className="login-pages" onSubmit={handleLogin}>
              <p>Login to your account</p>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  type="text"
                  placeholder="Phone Number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Button className="form-control btn btn-success mt-2" type="submit">
                Sign In
              </Button>

              <div className="form-links d-flex justify-content-around">
                <div>
              <span> Don't have an account? </span>
              <Link className="small text-muted" to="/signin">
                  <span className="login-link">Sign up here</span>
                
                </Link>
                </div>
                <div>
                <Link className="small text-muted ml-5" to="/forgotpassword">
                  <span className="login-link">Forgot Password</span>
                </Link>
                </div>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
}

export default Login;