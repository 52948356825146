import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { FiClock } from "react-icons/fi";
import { CiLocationOn } from "react-icons/ci";
import { useLocation, Link } from "react-router-dom";
import product99 from "./Images/106-1069282_no-product-no-product-found-hd-png-download.png";
import Breadcrumbs from "./Breadcrumbs";
const truncateDescription = (description) => {
  if (description.length > 50) {
    return `${description.substring(0, 50)}...`;
  }
  return description;
};

function ProductList() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const cat = searchParams.get("cat");
  const [products, setProducts] = useState([]);
  debugger;
  const [categoryNotFound, setCategoryNotFound] = useState(false);
  const [categories, setCategories] = useState([]);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [featuredProductsSelected, setFeaturedProductsSelected] = useState(false);
  const [latestProductsSelected, setLatestProductsSelected] = useState(false);

  const fetchProducts = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      let data = await response.json();

      // Sort products based on price
      if (sortBy === 'highToLow') {
        data.sort((a, b) => b.price - a.price); // High to Low
      } else if (sortBy === 'lowToHigh') {
        data.sort((a, b) => a.price - b.price); // Low to High
      }

      setProducts(data);
      setCategoryNotFound(false);
    } catch (error) {
      console.error("Error fetching products:", error);
      setProducts([]);
      setCategoryNotFound(true);
    }
  };


  useEffect(() => {
    fetchProducts(
      `https://electronicsapi.codingster.in/Products?area=null&category=${cat}`
    );
  }, [cat, sortBy]); // Include cat and sortBy in the dependency array for the first useEffect

  const truncateDescription = (description) => {
    if (description.length > 30) {
      return `${description.substring(0, 30)}...`;
    }
    return description;
  };
  const productname = (name) => {
    if (name.length > 20) {
      return `${name.substring(0, 20)}...`;
    }
    return name;
  };

  useEffect(() => {
    fetch("https://electronicsapi.codingster.in/List_Category")
      .then((response) => response.json())
      .then((data) => setCategories(data))
      .catch((error) =>
        console.error("Error fetching categories:", error)
      );
  }, []);

  const handleSortChange = (e) => {
    const selectedSortOption = e.target.value;
    setSortBy(selectedSortOption);
  };

  const handleFilterApply = () => {
    const url = `https://electronicsapi.codingster.in/Filters?pricemin=${minPrice}&pricemax=${maxPrice}&featured=${featuredProductsSelected}&latest=${latestProductsSelected}`;
    fetchProducts(url);
  };
  const breadcrumbItems = [
    { text: "Home", link: "/" },
    { text: "Products" }
  ];
  return (
    <div className="featured-products">

      <Container fluid>
      <Breadcrumbs items={breadcrumbItems} />
        <Row>
          <Col md={9}>
            {Array.isArray(products) ? (
              <Row id="products">
                {products.map((item, index) => (
                  <Col md={4} key={index} className="mb-4">
                    <Link
                      to={`/productdetails?productId=${item.id}`}
                      className="product-item text-decoration-none text-center"
                    >
                      <div className="custom-card product-card">
                        <img src={item.display_image} alt="Image" />
                        <div className="card-content">
                          {item.discount > 0 && (
                            <p className="productdiscount">{item.discount}% OFF</p>
                          )}
                          <div className="card-description">
                            <div className="d-flex justify-content-between">
                              <p className="categoryame text-uppercase">
                                {item.categoryname}
                              </p>
                              <p className="clock">
                                <FiClock />1 Day ago
                              </p>
                            </div>
                            <div className="productname">
                              <p>{productname(item.name)}</p>
                            </div>
                            <div className="prductdata">
                              <p>{truncateDescription(item.description)}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div className="location">
                                <p>
                                  <CiLocationOn /> <span>{item.area}</span>
                                </p>
                              </div>
                              <div className="price">
                                <p>${item.price}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Col>
                ))}
              </Row>
            ) : (
              <Row className="mt-5 mb-5 d-flex text-center justify-content-center product99">
                <img src={product99} alt="" />
              </Row>
            )}

          </Col>
          <Col md={3}>
            <Card className="mb-5">
              <div className="filter-card">
                <Card.Body>
                  <h5>Sort By Results</h5>
                  <Form.Select
                    value={sortBy}
                    onChange={handleSortChange}
                  >
                    <option value="">Select Sortby</option>
                    <option value="highToLow">High to Low</option>
                    <option value="lowToHigh">Low to High</option>
                  </Form.Select>
                </Card.Body>
              </div>

              <div className="filter-card mb-3">
                <Card.Body>
                  <h5>Filter ads by</h5>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input customcheckbox"
                      type="checkbox"
                      id="featuredProducts"
                      checked={featuredProductsSelected}
                      onChange={() => setFeaturedProductsSelected(!featuredProductsSelected)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="featuredProducts"
                    >
                      Featured
                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input customcheckbox"
                      type="checkbox"
                      id="latestProducts"
                      checked={latestProductsSelected}
                      onChange={() => setLatestProductsSelected(!latestProductsSelected)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="latestProducts"
                    >
                      Latest
                    </label>
                  </div>
                </Card.Body>
              </div>

              <Card.Body>
                <h5 className="mt-4">Filter By Price</h5>
                <div className=" row d-flex">
                  <form className="row">

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="minPrice">Min Price:</label>
                        <input
                          type="number"
                          className="form-control pricefilter"
                          id="minprice"
                          placeholder="Min"
                          value={minPrice}
                          onChange={(e) => setMinPrice(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="maxPrice">Max Price: </label>
                        <input
                          type="number"
                          className="form-control pricefilter"
                          id="maxprice"
                          placeholder="Max"
                          value={maxPrice}
                          onChange={(e) => setMaxPrice(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="text-center mt-3 filterbtn">
                      <Button className="btn" onClick={handleFilterApply}>
                        Apply
                      </Button>
                    </div>
                  </form>
                </div>
              </Card.Body>
            </Card>
            <Card className="filter-card text-center">
              <h5 className="product-categories mt-5">
                More Categories
              </h5>
              <Container>
                <div className="container-fluid mt-5">
                  <div className="row">
                    {categories.slice(0, showAllCategories ? categories.length : 6).map(category => (
                      <div className="col-12" key={category.id}>
                        <Link to={`/productslist?cat=${category.name}`} className=" card1">
                          <div className="cateory_div">
                            <div className=" d-flex justify-content-between">
                              <div className="d-flex">
                                {category.image && (
                                  <img src={category.image} alt={category.name} className="image-icon" />
                                )}
                                <p className="">{category.name}</p>
                              </div>
                              <div className="">
                                <p>{category.productcount}</p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
                {!showAllCategories && categories.length > 6 && (
                  <div className="text-start mb-3 mt-3">
                    <Button
                      className="button-product"
                      onClick={() => setShowAllCategories(true)}
                    >
                      Show More
                    </Button>
                  </div>
                )}
              </Container>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ProductList;