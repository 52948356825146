import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import axios from "axios";
import "./Css/Cart.css";
import OrderDetails from "./OrderDetails";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import "./Css/OrderDetails.css";
import cartemptyimg from "./Images/cart_emptyimg.png"
function Cart() {
  const [cart, setCart] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});
  const [couponamount, setCouponamount] = useState({})
  const [cartDetails, setCartDetails] = useState(null);
  const [selectedCartItem, setSelectedCartItem] = useState(null);
  const cartId = localStorage.getItem("cart_id");
  const authorId = localStorage.getItem("author_id");
  const cartcount = localStorage.getItem("cartcount");
  const [couponCode, setCouponCode] = useState("");
// const discountAmount = localStorage.getItem("couponamount")
  useEffect(() => {
    debugger;
    localStorage.removeItem("couponamount");
    fetchCartDetails();
    getCartDetails();
  }, []);


  const fetchCartDetails = async () => {
    debugger;
    try {
      if (!cartId) {
        throw new Error("Cart ID not found in localStorage");
      }

      const cartResponse = await axios.get(
        `https://electronicsapi.codingster.in/Cartlist?cart_id=${cartId}&author_id=${authorId}`
      );

      if (cartResponse.status === 200) {
        setCart(cartResponse.data);
        setSelectedCartItem(cartResponse.data[0]);
      }
    } catch (error) {
      console.error("Error fetching cart details:", error);
    }
  };

  const handleQuantityChange = async (e, id) => {
    debugger;
    const newQuantity = parseInt(e.target.value);
    debugger;
    try {

      const userType = 4;

      const requestData = {
        id: id,
        cart_id: cartId,
        authorid: authorId,
        usertype: userType,
        quantity: newQuantity,
      };

      const response = await axios.post(
        "https://electronicsapi.codingster.in/UpdateCart",
        requestData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      );

      if (response.data.responseCode === 0) {
        // OrderDetails();
        fetchCartDetails();
        getCartDetails();
        toast.success(response.data.responsemessage);

      }
    } catch (error) {
      console.error("Error updating cart:", error);
      throw error;
    }
  };

  debugger;
  const [error, setError] = useState(null);

  const getCartDetails = async () => {
    debugger;
    try {
      const response = await axios.get(`https://electronicsapi.codingster.in/Cartamount?cart_id=${cartId}`);
      setCartDetails(response.data);
    } catch (error) {
      console.error("Error fetching cart details:", error);
      setError("Error fetching cart details");
    }
  };

  // useEffect(() => {
  //   if (cartId) {
  //     getCartDetails(cartId);
  //   } else {
  //     setError("Cart ID not found in localStorage");
  //   }
  // }, []);

  //---------------------delete method----------------------//
  const handleDeleteItem = async (itemId) => {
    try {
      const response = await axios.post(
        "https://electronicsapi.codingster.in/DeleteCart",
        { id: itemId },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      );

      if (response.data.responseCode == 0) {
        fetchCartDetails();
        getCartDetails(cartId);
        toast.success(response.data.responsemessage);
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } else {
        throw new Error("Failed to delete item from cart");
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };
  //---------------cuopon apply------------//
  const handleApplyCoupon = async () => {
    debugger;
    try {
      const response = await axios.get(`https://electronicsapi.codingster.in/Couponcheck?cart_id=${cartId}&&authorid=${authorId}&&couponname=${couponCode}`);
      setCouponamount(response.data);
      localStorage.setItem("couponamount", response.data.discountamount)
      if (response.data.responseCode == 0) {
        toast.success("cuopon applied Successfully");
      }
      else {
        toast.error(response.data.responsemessage);
      }
    } catch (error) {
      console.error("Error fetching cart details:", error);
      setError("Error fetching cart details");
    }
  }
  //-----------------Remove Cuopon----------------//
  const handleremovecoupon = async () => {
    setCouponamount(null);
    localStorage.removeItem("couponamount");
    toast.success("cuopon Removed Successfully");
  }

  return (
    <Container>
      {cartcount > 0 ? (
        <Row className="mt-5 mb-5">
          <h2 className="bagcount">My Bag({cart.length} Item{cart.length !== 1 && "s"})</h2>
          {cart.length === 0 ? (
            <p>No products are added to the cart</p>
          ) : (
            <>
              <Col md={9} className="mt-5">
                <div className="carddetails">
                  {Array.isArray(cart) && cart.map((item, index) => (
                    <Row className="mb-4 divideproduct" key={index}>
                      <Col md={2}>
                        <img className="w-100" src={item.image} alt="" />
                      </Col>
                      <Col md={4}>
                        <p className="productname">{item.name}</p>
                        <p className="categoryname">Per Price:  ${item.per_price}</p>
                      </Col>
                      <Col md={6}>
                        <Row>
                          <Col md={4}>
                            <div className="d-flex">
                              <p className="mx-2">Qty: </p>
                              <select className="qtyselect" value={item.quantity} onChange={(e) => handleQuantityChange(e, item.id)}>
                                {Array.from({ length: item.stock }, (_, index) => (
                                  <option key={index + 1} value={index + 1}>{index + 1}</option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col md={8}>
                            <div className="d-flex">
                              <p className="mx-2 saving">Savings:</p>
                              <p className="saveamount">$ {item.discount_amount}</p>
                            </div>
                            <div className="mt-3 d-flex">
                              <p className="originalprice mt-2">$ {item.rate}</p>
                              <p className="discountper mt-2">({item.discount}%)</p>
                              <p className="productfinalprice">{item.amount}</p>
                            </div>
                            <div className="mt-4 text-center">
                              <p className="deletebtn" onClick={() => handleDeleteItem(item.id)}>Delete</p>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ))}
                </div>
              </Col>
              <Col md={3}>
                <OrderDetails
                  cartamount={cartDetails}
                  coupon={couponamount}
                />
                <Link to="/delivery" className="proceed-to-shipping-link">
                  <button className="proceed-to-shipping-btn" id="proceedbtn">
                    Proceed to Delivery
                  </button>
                </Link>
                {/* Coupon code section */}
                {authorId != null && (
                  <div className="coupon-code p-3 mt-5">
                    <h5 className="coupon-code-title mb-4">Coupon Code</h5>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control coupon-code-input"
                        placeholder="Enter coupon code"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                      />

                      <div className="input-group-append">
                        <button
                          className="btn btn-outline-secondary apply-coupon-btn"
                          type="button"
                          onClick={handleApplyCoupon}
                        >
                          Apply
                        </button>

                      </div>
                    </div>
                    {couponamount != null && (
                      <button className="btn removebtn" type="button" onClick={handleremovecoupon}>Remove</button>
                    )

                    }
                  </div>
                )}
              </Col>
            </>
          )}
        </Row>
      ) : (
        <Row className="mt-5 mb-5 d-flex text-center justify-content-center cartempty">
          <img src={cartemptyimg} alt="" />
          <h5>Your cart is empty</h5>
          <p>Looks like you have not added any thing to cart. Go <br />
            ahead and explore top products
          </p>
          <Link to="/home" className="explorebtn">
            Explore Products
          </Link>
        </Row>
      )}
      <ToastContainer />
    </Container>
  );
}

export default Cart;
